<template>
  <div class="home">
    <div class="home-type">
      <span class="home-type_label">{{ $t("casbee.label_input_format") }}</span>
      <div class="home-type_radio">
        <v-radio-group v-model="inputType" inline class="casbee-radio" :disabled="isDetailData">
          <v-radio v-for="itemType in itemsTypeInput" :key="itemType" :label="`${itemType}`" :value="itemType" :ripple="false"></v-radio>
        </v-radio-group>
      </div>
    </div>
    <component v-if="inputType !== null" :is="optionComponent" :detail-data="detailData" @onHandlerCalculationData="onHandlerCalculationData" />
  </div>
</template>
<script>
import { INPUT_TYPE, CASBEE_TYPE } from '@/constants/casbee';
export default {
  props: {
    detailData: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      inputType: INPUT_TYPE.CHOOSE_CONDITION,
      itemsTypeInput: Object.values(INPUT_TYPE),
    }
  },

  mounted() {
    this.prepareDetailData();
  },

  methods: {
    onHandlerCalculationData(data) {
      this.$emit('onHandlerCalculationData', data);
    },

    prepareDetailData() {
      if (!this.detailData.data) return;
      switch (this.detailData?.data[0]?.pattern_type) {
        case CASBEE_TYPE.HOME_CHOOSE_CONDITION:
          this.inputType = INPUT_TYPE.CHOOSE_CONDITION;
          break;
        case CASBEE_TYPE.HOME_ENTER_FREELY:
          this.inputType = INPUT_TYPE.ENTER_FREELY;
          break;
        default:
          break;
      }
    },
  },
  computed: {
    optionComponent() {
      let page = null;
      if (this.inputType === INPUT_TYPE.CHOOSE_CONDITION) {
        page = 'choose-condition';
      } else {
        page = 'enter-freely';
      }
      this.$emit('onHandlerCalculationData', {});
      return () => import(`@/views/emissions/dbCustomize/home/cat11-13/${page}.vue`);
    },
    isDetailData() {
      return Object.keys(this.detailData).length > 0 ? true : false;
    }
  },
}
</script>
<style scoped lang="scss">
.home {
  &-type {
    &_label {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
    }
    &_radio {
      margin-top: 19px;
    }
    @include desktop {
      &_label {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.05em;
    }
    }
  }
}
.home-type_radio {
  ::v-deep .v-input {
    .v-input__control {
      .v-input__slot {
        .v-input--radio-group__input {
          display: flex !important;
          flex-direction: column !important;
          gap: 18px !important;
        }
      }
    }
  }
  @include desktop {
    ::v-deep .v-input {
    .v-input__control {
      .v-input__slot {
        .v-input--radio-group__input {
          display: flex !important;
          flex-direction: row !important;
          gap: 20px;
        }
      }
    }
  }
  }
}
</style>